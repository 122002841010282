/************************************************
Breakpoints
************************************************/
/************************************************
Colors
************************************************/
* {
  box-sizing: border-box;
}

/************************************************
Browser Resets
************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
rem,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
rembed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

/************************************************
Older Browser Resets
************************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/************************************************
HTML
************************************************/
html {
  max-width: 100%;
  scroll-behavior: smooth;
  font-size: 23px;
  /************************************************
  Body
  ************************************************/
}
html.no-scroll {
  overflow: hidden;
}
@media (max-width: 1200px) {
  html {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 16px;
  }
}
html body {
  overflow-x: hidden;
  max-width: 100%;
  height: auto;
  /************************************************
  Animation Keyframes
  ************************************************/
  /************************************************
  General
  ************************************************/
  /************************************************
  Components
  ************************************************/
}
html body h1 {
  font-family: "futura-pt-bold", sans-serif;
  font-weight: bold;
}
html body h2, html body h3, html body h4, html body h5, html body h6 {
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
}
html body p {
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
}
html body h1 {
  font-size: 2.6rem;
}
html body h3 {
  font-size: 1.92rem;
}
html body h1, html body h2, html body h3, html body h4, html body h5, html body h6, html body p {
  color: white;
}
html body a {
  font-family: "futura-pt", sans-serif;
  color: #2A4AF3;
  transition: ease-in-out 0.3s opacity;
  font-weight: bold;
  text-decoration: none;
}
html body a:hover {
  opacity: 0.7;
}
@keyframes circularButterfly {
  0% {
    bottom: -150px;
    right: 150px;
    transform: rotate(0deg);
  }
  25% {
    bottom: 150px;
    right: 300px;
  }
  50% {
    bottom: 300px;
    right: 150px;
    transform: rotate(90deg);
  }
  75% {
    bottom: 150px;
    right: -100px;
  }
  100% {
    bottom: -150px;
    right: 150px;
    transform: rotate(-120deg);
  }
}
@keyframes trailingButterfly {
  10% {
    transform: translateY(-5vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-20vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-35vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-45vh) translateX(-15px) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-65vh) translateX(-50px) scale(1.6) rotate(-25deg) rotateY(20deg);
  }
}
@keyframes trailingButterflyMobile {
  10% {
    transform: translateY(-10px) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-20px) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-40px) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-55px) translateX(-2vw) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-85px) translateX(-30vw) scale(1.6) rotate(-25deg) rotateY(20deg);
  }
}
@keyframes trailingButterflyRight {
  10% {
    transform: translateY(-5vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-20vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-35vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-45vh) translateX(15px) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-65vh) translateX(50px) scale(1.6) rotate(25deg) rotateY(20deg);
  }
}
@keyframes trailingButterflyRightMobile {
  10% {
    transform: translateY(-10px) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-20px) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-40px) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-55px) translateX(-2vw) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-85px) translateX(30vw) scale(1.6) rotate(25deg) rotateY(20deg);
  }
}
@keyframes trailingButterflyRightShort {
  10% {
    transform: translateY(-5px) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-15px) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-25px) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-35px) translateX(15px) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-40px) translateX(50px) scale(1.6) rotate(25deg) rotateY(20deg);
  }
}
@keyframes trailingButterflyRightShortMobile {
  10% {
    transform: translateY(-2vh) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-3vh) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-4vh) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-5vh) translateX(-2vw) scale(1.2) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-5vh) translateX(33vw) scale(0.8) rotate(25deg) rotateY(20deg);
  }
}
@keyframes trailingButterflyShort {
  10% {
    transform: translateY(-3vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-7vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-15vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-25vh) translateX(-15px) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-35vh) translateX(-50px) scale(1.6) rotate(-25deg) rotateY(-20deg);
  }
}
@keyframes trailingButterflyShortMobile {
  10% {
    transform: translateY(-5px) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-15px) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-25px) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-35px) translateX(-2vw) scale(1.2) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-40px) translateX(-25vw) scale(1.1) rotate(-25deg) rotateY(-20deg);
  }
}
@keyframes trailingButterflyShortAlt {
  10% {
    transform: translateY(-3vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-7vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-15vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-25vh) translateX(15px) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-35vh) translateX(50px) scale(1.6) rotate(25deg) rotateY(20deg);
  }
}
@keyframes trailingButterflyRightShortAlt {
  10% {
    transform: translateY(-5vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-7vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-9vh) translateX(-15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-12vh) translateX(-15px) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-18vh) translateX(-50px) scale(1.6) rotate(-25deg) rotateY(-20deg);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
html body .pulse {
  animation: pulse 0.7s infinite;
}
html body .btn {
  background: white;
  color: black;
  padding: 14px 55px;
  display: block;
  width: -moz-max-content;
  width: max-content;
  border: none;
  box-shadow: 0px 5px 44px -8px rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  font-weight: 500;
  font-size: 1rem;
  font-family: "futura-pt", sans-serif;
  cursor: pointer;
}
html body .logo {
  position: fixed;
  top: 30px;
  left: 50px;
  z-index: 9;
}
@media (max-width: 1600px) {
  html body .logo {
    left: 2.5%;
    top: 20px;
  }
}
html body .logo img {
  max-width: 125px;
}
@media (max-width: 1600px) {
  html body .logo img {
    height: 100px;
    width: 100px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media (max-width: 991px) {
  html body .logo img {
    height: 90px;
    width: 90px;
  }
}
@media (max-width: 768px) {
  html body .logo img {
    height: 70px;
    width: 70px;
  }
}
@media (max-width: 576px) {
  html body .logo img {
    height: 60px;
    width: 60px;
  }
}
html body .global-back {
  position: fixed;
  top: 170px;
  left: 50px;
  z-index: 9;
}
@media (max-width: 1600px) {
  html body .global-back {
    left: 2.5%;
    top: 150px;
    padding: 7px 35px;
  }
}
@media (max-width: 991px) {
  html body .global-back {
    top: 130px;
  }
}
@media (max-width: 768px) {
  html body .global-back {
    top: 120px;
  }
}
@media (max-width: 576px) {
  html body .global-back {
    top: 100px;
  }
}
html body .global-back.hide {
  display: none;
}
html body form .org-text {
  text-align: left;
  font-size: 12px;
  padding-top: 5px;
}
html body form .file-text {
  text-align: left;
  cursor: pointer;
  pointer-events: none;
}
html body form .hide-file {
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
html body form .page {
  display: flex;
  flex-direction: column;
  gap: 34px;
}
@media (max-width: 1600px) {
  html body form .page {
    gap: 24px;
  }
}
html body form .page .row {
  display: flex;
  gap: 21px;
  width: 100%;
}
html body form .page .row .form-field {
  width: 100%;
}
html body form label {
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  color: white;
  position: relative;
  display: block;
  text-align: left;
  font-size: 1rem;
  margin-bottom: 10px;
}
html body form label.hide {
  display: none;
}
html body form label.no-margin {
  margin-bottom: 0;
}
html body form label.red {
  color: red;
  margin-bottom: 10px;
}
html body form input, html body form select {
  height: 34px;
  width: 100%;
  border: 1.5px solid #FFFFFF;
  border-radius: 3px;
  background: transparent;
  padding-left: 10px;
  font-size: 18px;
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  color: white;
}
html body form input::-moz-placeholder {
  font-size: 18px;
  font-family: "futura-pt", sans-serif;
  font-weight: 100;
  color: white;
}
html body form input::placeholder {
  font-size: 18px;
  font-family: "futura-pt", sans-serif;
  font-weight: 100;
  color: white;
}
@media (max-width: 768px) {
  html body form input::-moz-placeholder {
    font-size: 16px;
  }
  html body form input::placeholder {
    font-size: 16px;
  }
}
html body form select {
  cursor: pointer;
}
html body form select::-moz-placeholder {
  font-size: 18px;
  font-family: "futura-pt", sans-serif;
  font-weight: 100;
  color: black;
}
html body form select::placeholder {
  font-size: 18px;
  font-family: "futura-pt", sans-serif;
  font-weight: 100;
  color: black;
}
@media (max-width: 768px) {
  html body form select::-moz-placeholder {
    font-size: 16px;
  }
  html body form select::placeholder {
    font-size: 16px;
  }
}
html body form select option {
  color: black;
}
html body form input[type=file] {
  cursor: pointer;
}
html body .App {
  background: rgba(0, 0, 0, 0.5);
}
html body #page-animation {
  background: linear-gradient(0deg, #000000 0%, rgb(44, 44, 44) 149.15%, rgb(44, 44, 44) 149.15%);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  animation: fadeOut 0.7s ease-in-out;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
html body #page-animation.hide {
  display: none;
}
html body #hero, html body #form, html body #customizer, html body #submit, html body #portal-closed {
  background: linear-gradient(180deg, #000000 0%, rgba(44, 44, 44, 0.25) 149.15%, rgba(44, 44, 44, 0.25) 149.15%);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
html body #hero .btn {
  margin: auto;
}
html body #hero .text-wrapper {
  display: flex;
  flex-flow: column;
  gap: 68px;
  width: 725px;
  max-width: 96%;
}
@media (max-width: 576px) {
  html body #hero .text-wrapper {
    gap: 40px;
  }
}
html body #hero .text-wrapper .text-content {
  display: flex;
  flex-flow: column;
  gap: 30px;
}
@media (max-width: 576px) {
  html body #hero .text-wrapper .text-content {
    gap: 20px;
  }
}
html body #hero #butterfly-1 {
  position: absolute;
  left: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterflyRightShort 2.5s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #hero #butterfly-1 {
    animation: trailingButterflyRightShortMobile 2.5s ease-in-out;
    animation-fill-mode: forwards;
    left: 45vw;
  }
}
html body #hero #butterfly-1 img {
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #hero #butterfly-2 {
  position: absolute;
  left: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterfly 3.5s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #hero #butterfly-2 {
    animation: trailingButterflyMobile 3.5s ease-in-out;
    animation-fill-mode: forwards;
    left: 40vw;
  }
}
html body #hero #butterfly-2 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #hero #butterfly-3 {
  position: absolute;
  right: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterflyRight 3.5s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #hero #butterfly-3 {
    animation: trailingButterflyRightMobile 3.5s ease-in-out;
    animation-fill-mode: forwards;
    right: 40vw;
  }
}
html body #hero #butterfly-3 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #hero #butterfly-4 {
  position: absolute;
  right: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterflyShortAlt 3s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #hero #butterfly-4 {
    display: none;
  }
}
html body #hero #butterfly-4 img {
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #hero #butterfly-5 {
  position: absolute;
  right: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterflyRightShortAlt 2.5s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #hero #butterfly-5 {
    display: none;
  }
}
html body #hero #butterfly-5 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #hero #butterfly-6 {
  position: absolute;
  left: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterflyShort 3s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #hero #butterfly-6 {
    animation: trailingButterflyShortMobile 3s ease-in-out;
    animation-fill-mode: forwards;
    left: 38vw;
  }
}
html body #hero #butterfly-6 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #form, html body #portal-closed {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991px) {
  html body #form, html body #portal-closed {
    flex-flow: column-reverse;
    height: auto;
    min-height: 100vh;
    justify-content: center;
  }
}
html body #form .back, html body #portal-closed .back {
  position: absolute;
  left: 50px;
  top: -100px;
}
html body #form .next, html body #portal-closed .next {
  margin-top: 75px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}
@media (max-width: 1600px) {
  html body #form .next, html body #portal-closed .next {
    margin-top: 50px;
  }
}
@media (max-width: 768px) {
  html body #form .next, html body #portal-closed .next {
    margin-top: 30px;
  }
}
html body #form #butterfly-7, html body #portal-closed #butterfly-7 {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  bottom: 10px;
  z-index: 9;
}
html body #form #butterfly-7 img, html body #portal-closed #butterfly-7 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #form #butterfly-7.step3, html body #portal-closed #butterfly-7.step3 {
  animation: step2 2s ease-in-out;
  animation-fill-mode: both;
}
@keyframes step2 {
  10% {
    transform: translateY(-5vh) translateX(0) scale(1) rotate(-3deg) rotateY(-50deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-7vh) translateX(8vw) scale(1.1) rotate(3deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-9vh) translateX(10vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
  }
  70% {
    transform: translateY(-12vh) translateX(0vw) scale(1.4) rotate(3deg) rotateY(0);
  }
  100% {
    transform: translateY(-18vh) translateX(-5vw) scale(1.6) rotate(-25deg) rotateY(-20deg);
  }
}
html body #form #butterfly-7.step4, html body #portal-closed #butterfly-7.step4 {
  animation: step3 3s ease-in-out;
  animation-fill-mode: both;
}
@keyframes step3 {
  0% {
    transform: translateY(-18vh) translateX(-5vw) scale(1.6) rotate(-25deg) rotateY(-20deg);
    z-index: 9;
  }
  20% {
    transform: translateY(-25vh) translateX(-8vw) scale(1.5) rotate(10deg) rotateY(-50deg);
  }
  40% {
    transform: translateY(-20vh) translateX(-4vw) scale(1.6) rotate(15deg) rotateY(-40deg);
  }
  60% {
    transform: translateY(-22vh) translateX(0vw) scale(1.5) rotate(25deg) rotateY(-55deg);
  }
  80% {
    transform: translateY(-18vh) translateX(-3vw) scale(1.6) rotate(45deg) rotateY(-40deg);
  }
  100% {
    transform: translateY(-22vh) translateX(0vw) scale(1.5) rotate(55deg) rotateY(-30deg);
  }
}
html body #form #butterfly-7.step5, html body #portal-closed #butterfly-7.step5 {
  animation: step4 3s ease-in-out;
  animation-fill-mode: both;
}
@keyframes step4 {
  0% {
    transform: translateY(-22vh) translateX(0vw) scale(1.5) rotate(55deg) rotateY(-30deg);
  }
  20% {
    transform: translateY(-10vh) translateX(10vw) scale(1.4) rotate(25deg) rotateY(-80deg);
  }
  40% {
    transform: translateY(-15vh) translateX(5vw) scale(1.5) rotate(-15deg) rotateY(80deg);
  }
  60% {
    transform: translateY(-5vh) translateX(8vw) scale(1.6) rotate(-45deg) rotateY(-80deg);
  }
  80% {
    transform: translateY(-8vh) translateX(0vw) scale(1.5) rotate(-45deg) rotateY(80deg);
  }
  100% {
    transform: translateY(0vh) translateX(3vw) scale(1.4) rotate(-45deg) rotateY(0deg);
  }
}
html body #form #butterfly-7.step6, html body #portal-closed #butterfly-7.step6 {
  animation: step5 3s ease-in-out;
  animation-fill-mode: both;
}
@keyframes step5 {
  0% {
    transform: translateY(0vh) translateX(3vw) scale(1.4) rotate(-45deg) rotateY(80deg);
  }
  20% {
    transform: translateY(0vh) translateX(0vw) scale(1.4) rotate(-65deg) rotateY(-80deg);
  }
  40% {
    transform: translateY(0vh) translateX(6vw) scale(1.4) rotate(-85deg) rotateY(80deg);
  }
  60% {
    transform: translateY(0vh) translateX(-4vw) scale(1.4) rotate(-95deg) rotateY(-80deg);
  }
  80% {
    transform: translateY(0vh) translateX(-10vw) scale(1.4) rotate(-115deg) rotateX(-90deg) rotateY(180deg);
  }
  100% {
    transform: translateY(0vh) translateX(-5vw) scale(1.4) rotate(-115deg) rotateX(-180deg) rotateY(0deg);
  }
}
html body #form #butterfly-7.step7, html body #portal-closed #butterfly-7.step7 {
  animation: step6 1s ease-in-out;
  animation-fill-mode: both;
}
@keyframes step6 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
html body #form .form-wrapper, html body #portal-closed .form-wrapper {
  width: 50%;
  position: relative;
}
@media (max-width: 991px) {
  html body #form .form-wrapper, html body #portal-closed .form-wrapper {
    width: 100%;
    padding-bottom: 75px;
    margin-top: 290px;
  }
}
html body #form .form-wrapper.more-padding, html body #portal-closed .form-wrapper.more-padding {
  padding-top: 170px;
}
@media (max-width: 991px) {
  html body #form .form-wrapper.more-padding, html body #portal-closed .form-wrapper.more-padding {
    padding-top: 0;
    margin-top: 390px;
  }
}
html body #form .form-wrapper #contact, html body #portal-closed .form-wrapper #contact {
  margin: auto;
  max-width: 95%;
  width: 480px;
}
@media (max-width: 991px) {
  html body #form .form-wrapper #contact, html body #portal-closed .form-wrapper #contact {
    margin-top: 30px;
  }
}
html body #form .form-wrapper #contact .form-field, html body #form .form-wrapper #contact .page, html body #portal-closed .form-wrapper #contact .form-field, html body #portal-closed .form-wrapper #contact .page {
  transition: opacity 0.3s ease-in-out, top 0.5s ease-in-out, max-height 0.3s ease-in-out;
  opacity: 1;
  max-height: 100vh;
  top: 0;
  position: relative;
}
html body #form .form-wrapper #contact .form-field.hide, html body #form .form-wrapper #contact .page.hide, html body #portal-closed .form-wrapper #contact .form-field.hide, html body #portal-closed .form-wrapper #contact .page.hide {
  top: -150vh;
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  overflow: hidden;
  width: 0;
}
html body #form .form-wrapper #contact .form-field.hide *, html body #form .form-wrapper #contact .page.hide *, html body #portal-closed .form-wrapper #contact .form-field.hide *, html body #portal-closed .form-wrapper #contact .page.hide * {
  pointer-events: none;
}
html body #form .background-color, html body #portal-closed .background-color {
  width: 50%;
  height: 100%;
  background: linear-gradient(180deg, #353535 4.83%, rgba(0, 0, 0, 0) 230.89%);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  animation: fadeIn 0.3s ease-in-out;
}
@media (max-width: 991px) {
  html body #form .background-color, html body #portal-closed .background-color {
    display: none;
  }
}
html body .image-wrapper {
  width: 30vw;
  height: 75vh;
  left: 75%;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  html body .image-wrapper {
    background: unset;
    height: 350px;
    margin-top: 150px;
    width: 50%;
    top: 70px;
    left: 50%;
  }
}
html body .image-wrapper.mystery {
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  html body .image-wrapper.mystery {
    background: unset;
    height: 250px;
    margin-top: 150px;
    width: 50%;
    top: 70px;
    left: 50%;
  }
}
html body .image-wrapper.hide {
  opacity: 0;
  pointer-events: none;
  z-index: 0;
}
html body .image-wrapper.customizer {
  left: 50%;
  transition: all 0.7s ease-in-out;
  height: 50vh;
  top: 30%;
}
@media (max-width: 991px) {
  html body .image-wrapper.customizer {
    top: 15%;
  }
}
html body .image-wrapper.active-butterfly {
  height: 110vh;
  width: 30vw;
}
@media (max-width: 991px) {
  html body .image-wrapper.active-butterfly {
    width: 100vw;
  }
}
html body .image-wrapper.active-vertical-strip, html body .image-wrapper.active-neck {
  height: 110vh;
  width: 40vw;
  top: 60%;
}
@media (max-width: 991px) {
  html body .image-wrapper.active-vertical-strip, html body .image-wrapper.active-neck {
    height: 70vh;
    width: 100vw;
    top: 40%;
  }
}
html body .image-wrapper.active-proof-label {
  height: 110vh;
  width: 40vw;
  top: 0;
}
@media (max-width: 991px) {
  html body .image-wrapper.active-proof-label {
    height: 70vh;
    width: 100vw;
    top: 10%;
  }
}
html body .image-wrapper.submit {
  right: 5%;
  height: 60vh;
  width: 20vw;
  transition: all 0.7s ease-in-out;
  top: 50%;
  left: unset;
  transform: translateY(-50%);
}
html body .image-wrapper img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}
html body .image-wrapper .Necks {
  z-index: 2;
}
html body .image-wrapper .VerticalStrips {
  z-index: 1;
}
html body #customizer {
  display: flex;
  flex-flow: column;
  position: relative;
}
html body #customizer .btn {
  z-index: 15;
  box-shadow: none;
}
html body #customizer button {
  position: absolute;
  top: 50px;
  right: 50px;
}
@media (max-width: 991px) {
  html body #customizer button {
    padding: 7px 15px;
    right: 2.5%;
    top: 100px;
  }
}
html body #customizer #zoom-out {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 5;
  color: white;
  font-family: "futura-pt", sans-serif;
  border: 1px dashed white;
  padding: 7px 15px;
  font-size: 16px;
  opacity: 0.2;
  z-index: 15;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
}
html body #customizer #zoom-out.active {
  opacity: 1;
}
html body #customizer #zoom-out:hover {
  background: white;
  border: solid 1px white;
  color: black;
}
html body #customizer .top {
  height: 59.5%;
  width: 100%;
  background: radial-gradient(167.83% 167.83% at 50% 50%, #242424 0%, #000000 100%, #000000 100%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1.3s ease-in-out;
}
html body #customizer .top .image-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: all ease-in-out 0.5s;
  top: 0;
  will-change: height, top;
}
@media (max-width: 991px) {
  html body #customizer .top .image-wrapper {
    height: 60%;
  }
}
html body #customizer .top .image-wrapper.active-butterfly {
  height: 200%;
}
html body #customizer .top .image-wrapper.active-vertical-strip, html body #customizer .top .image-wrapper.active-neck {
  height: 200%;
  top: 50%;
}
html body #customizer .top .image-wrapper.active-proof-label {
  height: 200%;
  top: -50%;
}
html body #customizer .top .image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all ease-in-out 0.5s;
}
html body #customizer .top .image-wrapper .Necks {
  z-index: 2;
}
html body #customizer .top .image-wrapper .VerticalStrips {
  z-index: 1;
}
html body #customizer .bottom {
  height: 40.5%;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  border-top: 1.5px solid white;
  z-index: 13;
  animation: fadeIn 1.3s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
html body #customizer .bottom .white-blur {
  width: 100%;
  height: 138px;
  position: absolute;
  z-index: 0;
  background: white;
  top: 0;
  left: 0;
  filter: blur(186px);
}
html body #customizer .bottom .background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background: linear-gradient(180deg, #353535 0%, #242424 180.04%);
  top: 0;
  left: 0;
  z-index: 1;
}
html body #customizer .bottom .btns-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 1225px;
  max-width: 95%;
  margin: 0 auto;
}
@media (max-width: 991px) {
  html body #customizer .bottom .btns-wrapper {
    flex-wrap: wrap;
    gap: 30px;
  }
}
@media (max-width: 576px) {
  html body #customizer .bottom .btns-wrapper {
    gap: 20px;
  }
}
html body #customizer .bottom .btns-wrapper .btn {
  background: transparent;
  border: 1.5px solid #FFFFFF;
  color: white;
}
@media (max-width: 991px) {
  html body #customizer .bottom .btns-wrapper .btn {
    width: 45%;
    padding: 8px 10px;
  }
}
@media (max-width: 576px) {
  html body #customizer .bottom .btns-wrapper .btn {
    width: calc(50% - 10px);
  }
}
html body #customizer .bottom .btns-wrapper .btn.active {
  background: white;
  color: black;
}
html body #customizer .bottom .btns-wrapper .btn:hover {
  background: white;
  color: black;
}
html body #customizer .bottom .butterflies-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  width: 1370px;
  max-width: 95%;
  margin: 0 auto;
}
@media (max-width: 991px) {
  html body #customizer .bottom .butterflies-wrapper {
    overflow: scroll;
  }
}
html body #customizer .bottom .butterflies-wrapper .butterfly {
  cursor: pointer;
  width: 100%;
  text-align: center;
}
html body #customizer .bottom .butterflies-wrapper .butterfly img {
  width: 69px;
  height: 40px;
  -o-object-fit: contain;
     object-fit: contain;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
html body #customizer .bottom .butterflies-wrapper .butterfly h6 {
  margin-top: 5px;
  font-size: 16px;
}
html body #customizer .bottom .butterflies-wrapper .butterfly:hover img {
  transform: scale(1.1);
}
html body #submit #butterfly-8 {
  position: absolute;
  left: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterflyShort 3s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #submit #butterfly-8 {
    animation: trailingButterflyShortMobile 3s ease-in-out;
    animation-fill-mode: forwards;
  }
}
html body #submit #butterfly-8 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #submit #butterfly-9 {
  position: absolute;
  left: 150px;
  bottom: 10px;
  z-index: 9;
  animation: trailingButterflyRightShort 2.5s ease-in-out;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  html body #submit #butterfly-9 {
    animation: trailingButterflyRightShortMobile 2.5s ease-in-out;
    animation-fill-mode: forwards;
  }
}
html body #submit #butterfly-9 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
html body #submit #butterfly-10 {
  position: absolute;
  right: 150px;
  bottom: 10px;
  z-index: 15;
  animation: submitRight 3.3s ease-in-out;
  animation-fill-mode: forwards;
  display: none;
}
html body #submit #butterfly-10 img {
  width: 50px;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
@keyframes submitRight {
  0% {
    transform: translateY(-18vh) translateX(-5vw) scale(1) rotate(-25deg) rotateY(-50deg);
  }
  20% {
    transform: translateY(-14vh) translateX(-5vw) scale(1.1) rotate(10deg) rotateY(-100deg);
  }
  30% {
    transform: translateY(-22vh) translateX(-7vw) scale(1.1) rotate(20deg) rotateY(50deg);
  }
  50% {
    transform: translateY(-28vh) translateX(-3vw) scale(1) rotate(20deg) rotateY(40deg);
  }
  60% {
    transform: translateY(-26vh) translateX(-2vw) scale(1.2) rotate(25deg) rotateY(105deg);
  }
  80% {
    transform: translateY(-35vh) translateX(0vw) scale(1) rotate(45deg) rotateY(-40deg);
  }
  100% {
    transform: translateY(-36vh) translateX(4vw) scale(1.25) rotate(55deg) rotateY(-30deg);
  }
}
html body #submit #agree-rules {
  color: red;
  font-size: 12px;
  margin-bottom: 0;
}
html body #submit #agree-rules.hide {
  display: none;
}
html body #submit .submit-wrapper, html body #submit .submitted-wrapper {
  display: flex;
  flex-flow: column;
  width: 670px;
  max-width: 95%;
  text-align: left;
  align-items: center;
  gap: 30px;
  animation: fadeIn 1s ease-in-out;
  max-height: 200vh;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
html body #submit .submit-wrapper h1, html body #submit .submitted-wrapper h1 {
  text-align: center;
}
html body #submit .submit-wrapper a, html body #submit .submitted-wrapper a {
  text-align: center;
}
html body #submit .submit-wrapper .checkbox-wrapper, html body #submit .submitted-wrapper .checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
html body #submit .submit-wrapper .checkbox-wrapper input, html body #submit .submitted-wrapper .checkbox-wrapper input {
  margin-right: 10px;
}
html body #submit .submit-wrapper .checkbox-wrapper label, html body #submit .submitted-wrapper .checkbox-wrapper label {
  font-size: 16px;
  font-family: "futura-pt", sans-serif;
  color: white;
}
html body #submit .submit-wrapper.hide, html body #submit .submitted-wrapper.hide {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
  max-width: 0;
}
html body #submit .submit-wrapper.hide *, html body #submit .submitted-wrapper.hide * {
  pointer-events: none;
}
html body #submit #loading.hide {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
  max-width: 0;
}
html body #submit #loading.hide * {
  pointer-events: none;
}
html body #submit .submitted-wrapper {
  text-align: center;
}
html body #login, html body #logged-in {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #000000 0%, rgb(44, 44, 44) 149.15%, rgb(44, 44, 44) 149.15%);
}
html body #login h1, html body #logged-in h1 {
  margin-bottom: 30px;
}
html body #login .btn, html body #logged-in .btn {
  margin: auto;
  padding: 8px 30px;
}
html body #login .data-wrapper, html body #logged-in .data-wrapper {
  height: 60vh;
  overflow: scroll;
  width: 70vw;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 30px 0;
}
html body #login .data-wrapper h3, html body #logged-in .data-wrapper h3 {
  width: 100%;
}
html body #login .data-wrapper .data-item, html body #logged-in .data-wrapper .data-item {
  border: 1px solid #ccc;
  padding: 20px;
  width: 100%;
  max-width: 600px;
}
html body #login .data-wrapper .color-choice, html body #login .data-wrapper .form-submission, html body #login .data-wrapper .file-url, html body #logged-in .data-wrapper .color-choice, html body #logged-in .data-wrapper .form-submission, html body #logged-in .data-wrapper .file-url {
  margin-top: 20px;
}
html body #login .data-wrapper .color-choice, html body #logged-in .data-wrapper .color-choice {
  padding: 20px;
}
html body #login .data-wrapper .form-submission, html body #logged-in .data-wrapper .form-submission {
  padding: 20px;
}
html body #login .data-wrapper .file-url, html body #logged-in .data-wrapper .file-url {
  border-top: 1px solid #ccc;
  margin-top: 40px;
  padding-top: 20px;
}
html body #login .data-wrapper h2, html body #logged-in .data-wrapper h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
html body #login .data-wrapper strong, html body #logged-in .data-wrapper strong {
  font-weight: bold;
}
html body #portal-closed .page {
  gap: 15px;
}
html body #portal-closed #mystery-bottle {
  z-index: 5;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
}
html body #portal-closed .logo {
  position: absolute !important;
}
html body #portal-closed #contact {
  width: 100% !important;
}
html body #portal-closed #contact .row, html body #portal-closed #contact p {
  width: 480px;
  max-width: 100%;
  margin: auto;
}
html body #portal-closed #contact h3 {
  font-size: 1.5rem;
}
html body #portal-closed #butterfly-7 {
  z-index: 999999;
}
html body #portal-closed .form-wrapper {
  z-index: 999999;
}
html body #portal-closed .hide {
  display: none;
}
html body #portal-closed #text-wrapper {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  z-index: 50;
  display: flex;
  flex-flow: column;
  gap: 15px;
}
@media (max-width: 991px) {
  html body #portal-closed #text-wrapper {
    width: 100%;
    left: 50%;
    top: 150px;
    padding: 30px;
  }
}
html body #portal-closed #text-wrapper p h4 {
  display: inline-block;
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  html body #portal-closed #text-wrapper p h4 {
    font-size: 1.2rem;
  }
}
html body #portal-closed #text-wrapper h2 {
  font-size: 2.2rem;
}
html body #portal-closed .submit-text {
  display: flex;
  flex-flow: column;
  gap: 15px;
}
html body #portal-closed .submit-text .small {
  font-size: 15px;
  margin-top: 20px;
}
html body #portal-closed .submit-text .social-icons {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
html body #portal-closed .submit-text .social-icons img {
  width: 30px;
  height: 30px;
  filter: invert(1);
}

#loading {
  --bg-image: var(--body-image);
  --animation-time: 3s;
  --dot-size: 20px;
  --dots-length: calc(7 * var(--dot-size));
  --loading-width: 100vw;
  width: var(--loading-width);
  height: var(--dot-size);
  display: flex;
  position: relative;
  overflow: hidden;
  /*   background: rgba(0,0,0,0.2); */
}

#loading::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to right, var(--bg-image), transparent 10% 90%, var(--bg-image));
}

#loading > span {
  display: inline-block;
  position: absolute;
  background: #2A4AF3;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 50%;
  --initial-left: 0px;
}

#loading > span:nth-child(1) {
  --initial-left: 0px;
  --animation-delay: calc(4/10 * var(--animation-time));
}

#loading > span:nth-child(2) {
  --initial-left: calc(1.5 * var(--dot-size));
  --animation-delay: calc(3/10 * var(--animation-time));
}

#loading > span:nth-child(3) {
  --initial-left: calc(3 * var(--dot-size));
  --animation-delay: calc(2/10 * var(--animation-time));
}

#loading > span:nth-child(4) {
  --initial-left: calc(4.5 * var(--dot-size));
  --animation-delay: calc(1/10 * var(--animation-time));
}

#loading > span:nth-child(5) {
  --initial-left: calc(6 * var(--dot-size));
  --animation-delay: calc(0/10 * var(--animation-time));
}

#loading > span {
  animation: move var(--animation-time) cubic-bezier(0.3, 1, 0.5, 0) infinite;
  animation-delay: var(--animation-delay);
  left: calc(var(--initial-left) - var(--dots-length));
}

@keyframes move {
  50%, 100% {
    left: calc(100% + var(--initial-left));
  }
}/*# sourceMappingURL=style.css.map */