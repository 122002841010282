/************************************************
Breakpoints
************************************************/
@mixin breakpoint($class) {
	@if $class==xxs {
		@media (max-width: 400px) {
			@content;
		}
	}

	@else if $class==xs {
		@media (max-width: 425px) {
			@content;
		}
	}

	@else if $class==sm {
		@media (max-width: 576px) {
			@content;
		}
	}

	@else if $class==md {
		@media (max-width: 768px) {
			@content;
		}
	}

	@else if $class==lg {
		@media (max-width: 991px) {
			@content;
		}
	}

	@else if $class==xl {
		@media (max-width: 1200px) {
			@content;
		}
	}

	@else if $class==mair {
		@media (max-width: 1350px) {
			@content;
		}
	}

	@else if $class==xxl {
		@media (max-width: 1600px) {
			@content;
		}
	}

	@else {
		@warn "Breakpoint mixin supports: sm, md, lg, xxl";
	}
}

/************************************************
Colors
************************************************/
$dark-blue:#0b0a26;
$light-blue:#1b7ea6;
$footer-color: #102843;

$blue : #2A4AF3;
$luxury-white: #FAFAFA;
$silver: #E9E9E9;
$japanese-indigo: #243C57;
$yankees-blue: #102843;

$black: #000000; //For general font color
$links: green; //change this to whatever the secondary overall color for the website is

$futura-bold: "futura-pt-bold", sans-serif;
$futura: "futura-pt", sans-serif;


* {
	box-sizing: border-box;
}

/************************************************
Browser Resets
************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
rem,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
rembed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}

/************************************************
Older Browser Resets
************************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
/************************************************
HTML
************************************************/
html {
	max-width: 100%;
	scroll-behavior: smooth;

	&.no-scroll {
		overflow: hidden;
	}

	font-size: 23px; //default

	@include breakpoint(xl) {
		font-size: 22px;
	}

	@include breakpoint(lg) {
		font-size: 20px;
	}

	@include breakpoint(md) {
		font-size: 18px;
	}

	@include breakpoint(sm) {
		font-size: 16px;
	}

	/************************************************
	Body
	************************************************/
	body {
		overflow-x: hidden;
		max-width: 100%;
		height: auto;

        h1 {
            font-family: "futura-pt-bold", sans-serif;
            font-weight: bold;
        }
        h2,h3,h4,h5,h6 {
            font-family: "futura-pt", sans-serif;
            font-weight: 500;
        }
        p {
            font-family: "futura-pt", sans-serif;
            font-weight: 300;
        }
        h1 {
            font-size: 2.6rem;
        }
        h2 {

        }
        h3 {
            font-size: 1.92rem;
        }
        h1,h2,h3,h4,h5,h6,p {
            color: white;
        }
        a {
            font-family: $futura;
            color: $blue;
            transition: ease-in-out .3s opacity;
            font-weight: bold;
            text-decoration: none;
            &:hover {
                opacity: .7;
            }
        }
        /************************************************
        Animation Keyframes
        ************************************************/
        @keyframes circularButterfly {
            0% {
              bottom: -150px;
              right: 150px;
              transform: rotate(0deg);
            }
            25% {
                bottom: 150px;
                right: 300px;
            }
            50% {
                bottom: 300px;
                right: 150px;
                transform: rotate(90deg);
            }
            75% {
                bottom: 150px;
                right: -100px;
            }
            100% {
              bottom: -150px;
              right: 150px;
              transform: rotate(-120deg);
            }
        }
        @keyframes trailingButterfly {
            10% {
                transform: translateY(-5vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
                opacity: 1;
            }
            30% {
                transform: translateY(-20vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
                transform: translateY(-35vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
                transform: translateY(-45vh) translateX(-15px) scale(1.4) rotate(3deg) rotateY(0);
            }
            100% {
                transform: translateY(-65vh) translateX(-50px) scale(1.6) rotate(-25deg) rotateY(20deg);
            }
        }
        @keyframes trailingButterflyMobile {
            10% {
                transform: translateY(-10px) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
                opacity: 1;
            }
            30% {
                transform: translateY(-20px) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
                transform: translateY(-40px) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
                transform: translateY(-55px) translateX(-2vw) scale(1.4) rotate(3deg) rotateY(0);
            }
            100% {
                transform: translateY(-85px) translateX(-30vw) scale(1.6) rotate(-25deg) rotateY(20deg);
            }
        }
        @keyframes trailingButterflyRight {
            10% {
              transform: translateY(-5vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
              opacity: 1;
            }
            30% {
              transform: translateY(-20vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
              transform: translateY(-35vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
              transform: translateY(-45vh) translateX(15px) scale(1.4) rotate(3deg) rotateY(0);

            }
            100% {
              transform: translateY(-65vh) translateX(50px) scale(1.6) rotate(25deg) rotateY(20deg);
            }
      }
        @keyframes trailingButterflyRightMobile {
            10% {
            transform: translateY(-10px) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
            opacity: 1;
            }
            30% {
            transform: translateY(-20px) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
            transform: translateY(-40px) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
            transform: translateY(-55px) translateX(-2vw) scale(1.4) rotate(3deg) rotateY(0);

            }
            100% {
            transform: translateY(-85px) translateX(30vw) scale(1.6) rotate(25deg) rotateY(20deg);
            }
        }
        @keyframes trailingButterflyRightShort {
            10% {
            transform: translateY(-5px) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
            opacity: 1;
            }
            30% {
            transform: translateY(-15px) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
            transform: translateY(-25px) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
            transform: translateY(-35px) translateX(15px) scale(1.4) rotate(3deg) rotateY(0);

            }
            100% {
            transform: translateY(-40px) translateX(50px) scale(1.6) rotate(25deg) rotateY(20deg);
            }
        }
        @keyframes trailingButterflyRightShortMobile {
            10% {
            transform: translateY(-2vh) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
            opacity: 1;
            }
            30% {
            transform: translateY(-3vh) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
            transform: translateY(-4vh) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
            transform: translateY(-5vh) translateX(-2vw) scale(1.2) rotate(3deg) rotateY(0);

            }
            100% {
            transform: translateY(-5vh) translateX(33vw) scale(.8) rotate(25deg) rotateY(20deg);
            }
        }
        @keyframes trailingButterflyShort {
            10% {
            transform: translateY(-3vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
            opacity: 1;
            }
            30% {
            transform: translateY(-7vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
            transform: translateY(-15vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
            transform: translateY(-25vh) translateX(-15px) scale(1.4) rotate(3deg) rotateY(0);

            }
            100% {
            transform: translateY(-35vh) translateX(-50px) scale(1.6) rotate(-25deg) rotateY(-20deg);
            }
        }
        @keyframes trailingButterflyShortMobile {
            10% {
            transform: translateY(-5px) translateX(1vw) scale(1) rotate(-3deg) rotateY(-50deg);
            opacity: 1;
            }
            30% {
            transform: translateY(-15px) translateX(-1vw) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
            transform: translateY(-25px) translateX(2vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
            transform: translateY(-35px) translateX(-2vw) scale(1.2) rotate(3deg) rotateY(0);

            }
            100% {
            transform: translateY(-40px) translateX(-25vw) scale(1.1) rotate(-25deg) rotateY(-20deg);
            }
        }
        @keyframes trailingButterflyShortAlt {
            10% {
            transform: translateY(-3vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
            opacity: 1;
            }
            30% {
            transform: translateY(-7vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
            transform: translateY(-15vh) translateX(15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
            transform: translateY(-25vh) translateX(15px) scale(1.4) rotate(3deg) rotateY(0);

            }
            100% {
            transform: translateY(-35vh) translateX(50px) scale(1.6) rotate(25deg) rotateY(20deg);
            }
        }
        @keyframes trailingButterflyRightShortAlt {
            10% {
            transform: translateY(-5vh) translateX(25px) scale(1) rotate(-3deg) rotateY(-50deg);
            opacity: 1;
            }
            30% {
            transform: translateY(-7vh) translateX(-25px) scale(1.1) rotate(3deg) rotateY(50deg);
            }
            50% {
            transform: translateY(-9vh) translateX(-15px) scale(1.3) rotate(-3deg) rotateY(-50deg);
            }
            70% { 
            transform: translateY(-12vh) translateX(-15px) scale(1.4) rotate(3deg) rotateY(0);

            }
            100% {
            transform: translateY(-18vh) translateX(-50px) scale(1.6) rotate(-25deg) rotateY(-20deg);
            }
        }
        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.05);
            }
            100% {
                transform: scale(1);
            }
        }
        .pulse {        
            animation: pulse .7s infinite;
        }

        /************************************************
        General
        ************************************************/

        .btn {
            background: white;
            color: black;
            padding: 14px 55px;
            display: block;
            width: max-content;
            border: none;
            box-shadow: 0px 5px 44px -8px rgba(255, 255, 255, 0.3);
            border-radius: 1px;
            font-weight: 500;
            font-size: 1rem;
            font-family: $futura;
            cursor: pointer;
        }

        .logo {
            position: fixed;
            top: 30px;
            left: 50px;
            z-index: 9;
            @include breakpoint(xxl) {
                left: 2.5%;
                top: 20px;
            }
            img {
                max-width: 125px;
                @include breakpoint(xxl) {
                    height: 100px;
                    width: 100px;
                    object-fit: contain;
                }
                @include breakpoint(lg) {
                    height: 90px;
                    width: 90px;
                }
                @include breakpoint(md) {
                    height: 70px;
                    width: 70px;
                }
                @include breakpoint(sm) {
                    height: 60px;
                    width: 60px;
                }
            }
        }
        .global-back {
            position: fixed;
            top: 170px;
            left: 50px;
            z-index: 9;
            @include breakpoint(xxl) {
                left: 2.5%;
                top: 150px;
                padding: 7px 35px;
            }
            @include breakpoint(lg) {
                top: 130px;
            }
            @include breakpoint(md) {
                top: 120px;
            }
            @include breakpoint(sm) {
                top: 100px;
            }
            &.hide {
                display: none;
            }
        }

        form {
            .org-text {
                text-align: left;
                font-size: 12px;
                padding-top: 5px;
            }
            .file-text {
                text-align: left;
                cursor: pointer;
                pointer-events: none;
            }
            .hide-file {
                opacity: 0;
                position: absolute;
                left: 0;
                bottom: 0;
                cursor: pointer;
            }
            .page {
                display: flex;
                flex-direction: column;
                gap: 34px;
                @include breakpoint(xxl) {
                    gap: 24px;
                }
                .row {
                    display: flex;
                    gap: 21px;
                    width: 100%;
                    .form-field {
                        width: 100%;
                    }
                }
            }
            label {
                font-family: "futura-pt", sans-serif;
                font-weight: 600;
                color: white;
                position: relative;
                display: block;
                text-align: left;
                font-size: 1rem;
                margin-bottom: 10px;
                &.hide {
                    display: none;
                }
                &.no-margin {
                    margin-bottom: 0;
                }
                &.red {
                    color: red;
                    margin-bottom: 10px;
                }
            }
            input,select {
                height: 34px;
                width: 100%;
                border: 1.5px solid #FFFFFF;
                border-radius: 3px;
                background: transparent;
                padding-left: 10px;
                font-size: 18px;
                font-family: $futura;
                font-weight: 600;
                color: white;
            }
            input {
                &::placeholder {
                    font-size: 18px;
                    font-family: $futura;
                    font-weight: 100;
                    color: white;
                    @include breakpoint(md) {
                        font-size: 16px;
                    }
                }
            }
            select {
                cursor: pointer;
                &::placeholder {
                    font-size: 18px;
                    font-family: $futura;
                    font-weight: 100;
                    color: black;
                    @include breakpoint(md) {
                        font-size: 16px;
                    }
                }
                option {
                    color: black; // color of all the other options
                }
            }
            input[type="file"] {
                cursor: pointer;
            }
        }

        /************************************************
        Components
        ************************************************/
        .App {
            background: rgba(0, 0, 0, .5);
        }
        #page-animation {
            background: linear-gradient(0deg, #000000 0%, rgba(44, 44, 44, 1) 149.15%, rgba(44, 44, 44, 1) 149.15%);
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            animation: fadeOut .7s ease-in-out;
            @keyframes fadeOut {
                0% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
            &.hide {
                display: none;
            }
        }
        #hero, #form, #customizer, #submit, #portal-closed {
            background: linear-gradient(180deg, #000000 0%, rgba(44, 44, 44, 0.25) 149.15%, rgba(44, 44, 44, 0.25) 149.15%);
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }

        #hero {
            .btn {
                margin: auto;
            }
            .text-wrapper {
                display: flex;
                flex-flow: column;
                gap: 68px;
                width: 725px;
                max-width: 96%;
                @include breakpoint(sm) {
                    gap: 40px;
                }
                .text-content {
                    display: flex;
                    flex-flow: column;
                    gap: 30px;
                    @include breakpoint(sm) {
                        gap: 20px;
                    }
                }
            }
            //hide all butterflies
            #butterfly-1, #butterfly-2, #butterfly-3, #butterfly-4, #butterfly-5 {
                //display: none;
            }

            #butterfly-1 {
                position: absolute;
                left: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterflyRightShort 2.5s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    animation: trailingButterflyRightShortMobile 2.5s ease-in-out;
                    animation-fill-mode: forwards;
                    left: 45vw;
                }
                img {
                    width: 60px;
                    height: 60px;
                    object-fit: contain;
                }
            }
            #butterfly-2 {
                position: absolute;
                left: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterfly 3.5s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    animation: trailingButterflyMobile 3.5s ease-in-out;
                    animation-fill-mode: forwards;
                    left: 40vw;
                }
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
            #butterfly-3 {
                position: absolute;
                right: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterflyRight 3.5s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    animation: trailingButterflyRightMobile 3.5s ease-in-out;
                    animation-fill-mode: forwards;
                    right: 40vw;
                }
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
            #butterfly-4 {
                position: absolute;
                right: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterflyShortAlt 3s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    display: none;
                }
                img {
                    width: 60px;
                    height: 60px;
                    object-fit: contain;
                }
            }
            #butterfly-5 {
                position: absolute;
                right: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterflyRightShortAlt 2.5s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    display: none;
                }
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
            #butterfly-6 {
                position: absolute;
                left: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterflyShort 3s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    animation: trailingButterflyShortMobile 3s ease-in-out;
                    animation-fill-mode: forwards;
                    left: 38vw;
                }
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
        }
        #form, #portal-closed {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include breakpoint(lg) {
                flex-flow: column-reverse;
                height: auto;
                min-height: 100vh;
                justify-content: center;
            }
            .back {
                position: absolute;
                left: 50px;
                top: -100px;
            }
            .next {
                margin-top: 75px;
                left: 50%;
                transform: translateX(-50%);
                position: relative;
                @include breakpoint(xxl) {
                    margin-top: 50px;
                }
                @include breakpoint(md) {
                    margin-top: 30px;
                }
            }

            #butterfly-7 {
                position: absolute;
                right: 50%;
                top: 50%;
                transform: translate(50%, -50%);
                bottom: 10px;
                z-index: 9;
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
                &.step3 {
                    animation: step2 2s ease-in-out;
                    animation-fill-mode: both;
                    @keyframes step2 {
                        10% {
                        transform: translateY(-5vh) translateX(0) scale(1) rotate(-3deg) rotateY(-50deg);
                        opacity: 1;
                        }
                        30% {
                        transform: translateY(-7vh) translateX(8vw) scale(1.1) rotate(3deg) rotateY(50deg);
                        }
                        50% {
                        transform: translateY(-9vh) translateX(10vw) scale(1.3) rotate(-3deg) rotateY(-50deg);
                        }
                        70% { 
                        transform: translateY(-12vh) translateX(0vw) scale(1.4) rotate(3deg) rotateY(0);
            
                        }
                        100% {
                        transform: translateY(-18vh) translateX(-5vw) scale(1.6) rotate(-25deg) rotateY(-20deg);
                        }
                    }
                }
                &.step4 {
                    animation: step3 3s ease-in-out;
                    animation-fill-mode: both;
                    @keyframes step3 {
                        0% {
                          transform: translateY(-18vh) translateX(-5vw) scale(1.6) rotate(-25deg) rotateY(-20deg);
                          z-index: 9;
                        }
                        20% {
                          transform: translateY(-25vh) translateX(-8vw) scale(1.5) rotate(10deg) rotateY(-50deg) ;
                        }
                        40% {
                          transform: translateY(-20vh) translateX(-4vw) scale(1.6) rotate(15deg) rotateY(-40deg) ;
                        }
                        60% {
                          transform: translateY(-22vh) translateX(0vw) scale(1.5) rotate(25deg) rotateY(-55deg) ;
                        }
                        80% {
                          transform: translateY(-18vh) translateX(-3vw) scale(1.6) rotate(45deg) rotateY(-40deg) ;
                        }
                        100% {
                          transform: translateY(-22vh) translateX(0vw) scale(1.5) rotate(55deg) rotateY(-30deg) ;
                        }
                    }                                   
                }
                &.step5 {
                    animation: step4 3s ease-in-out;
                    animation-fill-mode: both;
                    @keyframes step4 {
                        0% {
                          transform: translateY(-22vh) translateX(0vw) scale(1.5) rotate(55deg) rotateY(-30deg);
                        }
                        20% {
                          transform: translateY(-10vh) translateX(10vw) scale(1.4) rotate(25deg) rotateY(-80deg);
                        }
                        40% {
                          transform: translateY(-15vh) translateX(5vw) scale(1.5) rotate(-15deg) rotateY(80deg);
                        }
                        60% {
                          transform: translateY(-5vh) translateX(8vw) scale(1.6) rotate(-45deg) rotateY(-80deg);
                        }
                        80% {
                          transform: translateY(-8vh) translateX(0vw) scale(1.5) rotate(-45deg) rotateY(80deg);
                        }
                        100% {
                          transform: translateY(0vh) translateX(3vw) scale(1.4) rotate(-45deg) rotateY(0deg);
                        }
                    }                                                                          
                }
                &.step6 {
                    animation: step5 3s ease-in-out;
                    animation-fill-mode: both;
                    @keyframes step5 {
                        0% {
                            transform: translateY(0vh) translateX(3vw) scale(1.4) rotate(-45deg) rotateY(80deg);
                        }
                        20% {
                            transform: translateY(0vh) translateX(0vw) scale(1.4) rotate(-65deg) rotateY(-80deg);
                        }
                        40% {
                            transform: translateY(0vh) translateX(6vw) scale(1.4) rotate(-85deg) rotateY(80deg);
                        }
                        60% {
                            transform: translateY(0vh) translateX(-4vw) scale(1.4) rotate(-95deg) rotateY(-80deg);
                        }
                        80% {
                            transform: translateY(0vh) translateX(-10vw) scale(1.4) rotate(-115deg) rotateX(-90deg) rotateY(180deg);
                        }
                        100% {
                            transform: translateY(0vh) translateX(-5vw) scale(1.4) rotate(-115deg)rotateX(-180deg) rotateY(0deg);
                        }
                    }                                                                          
                }
                &.step7 {
                    animation: step6 1s ease-in-out;
                    animation-fill-mode: both;
                    @keyframes step6 {
                        0% {
                            opacity: 1;
                        }
                        100% {
                            opacity: 0;
                        }
                    }                                                                          
                }
            }

            .form-wrapper {
                width: 50%;
                position: relative;
                @include breakpoint(lg) {
                    width: 100%;
                    padding-bottom: 75px;
                    margin-top: 290px;
                }
                &.more-padding {
                    padding-top: 170px;
                    @include breakpoint(lg) {
                        padding-top: 0;
                        margin-top: 390px;
                    }
                }
                #contact {
                    margin: auto;
                    max-width: 95%;
                    width: 480px;
                    @include breakpoint(lg) {
                        margin-top: 30px;
                    }
                    .form-field, .page {
                        transition: opacity .3s ease-in-out, top .5s ease-in-out, max-height .3s ease-in-out;
                        opacity: 1;
                        max-height: 100vh;
                        top: 0;
                        position: relative;
                        &.hide {
                            top: -150vh;
                            opacity: 0;
                            pointer-events: none;
                            max-height: 0;
                            overflow: hidden;
                            width: 0;
                            * {
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
            .background-color {
                width: 50%;
                height: 100%;
                background: linear-gradient(180deg, #353535 4.83%, rgba(0, 0, 0, 0) 230.89%);
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                animation: fadeIn .3s ease-in-out;
                @include breakpoint(lg) {
                    display: none;
                }
            }
        }
        .image-wrapper {
            width: 30vw;
            height: 75vh;
            left: 75%;
            transform: translate(-50%, -50%);
            top: 50%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 12;
            opacity: 1;
            transition: all .3s ease-in-out;
            @include breakpoint(lg) {
                background: unset;
                height: 350px;
                margin-top: 150px;
                width: 50%;
                top: 70px;
                left: 50%;
            }
            &.mystery {
                width: 100%;
                height: 100%;
                @include breakpoint(lg) {
                    background: unset;
                    height: 250px;
                    margin-top: 150px;
                    width: 50%;
                    top: 70px;
                    left: 50%;
                }
            }
            &.hide {
                opacity: 0;
                pointer-events: none;
                z-index: 0;
            }
            &.customizer {
                left: 50%;
                transition: all .7s ease-in-out;
                height: 50vh;
                top: 30%;
                @include breakpoint(lg) {
                    top: 15%;
                }
            }
            &.active-butterfly {
                height: 110vh;
                width: 30vw;
                @include breakpoint(lg) {
                    width: 100vw;
                }
            }
            &.active-vertical-strip, &.active-neck {
                height: 110vh;
                width: 40vw;
                top: 60%;
                @include breakpoint(lg) {
                    height: 70vh;
                    width: 100vw;
                    top: 40%;
                }
            }
            &.active-proof-label {
                height: 110vh;
                width: 40vw;
                top: 0;
                @include breakpoint(lg) {
                    height: 70vh;
                    width: 100vw;
                    top: 10%;
                }
            }
            &.submit {
                right: 5%;
                height: 60vh;
                width: 20vw;
                transition: all 0.7s ease-in-out;
                top: 50%;
                left: unset;
                transform: translateY(-50%);
            }
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                position: absolute;
                top: 0;
                left: 0;
            }
            .Necks {
                z-index: 2;
            }

            .VerticalStrips {
                z-index: 1;
            }
        }

        #customizer {
            display: flex;
            flex-flow: column;
            position: relative;
            .btn {
                z-index: 15;
                box-shadow: none;
            }
            button {
                position: absolute;
                top: 50px;
                right: 50px;
                @include breakpoint(lg) {
                    padding: 7px 15px;
                    right: 2.5%;
                    top: 100px;
                }
            }
            #zoom-out {
                position: absolute;
                bottom: 20px;
                right: 20px;
                z-index: 5;
                color: white;
                font-family: $futura;
                border: 1px dashed white;
                padding: 7px 15px;
                font-size: 16px;
                opacity: .2;
                z-index: 15;
                cursor: pointer;
                transition: all ease-in-out .5s;
                &.active {
                    opacity: 1;
                }
                &:hover {
                    background: white;
                    border: solid 1px white;
                    color: black;
                }
            }
            .top {
                height: 59.5%;
                width: 100%;
                background: radial-gradient(167.83% 167.83% at 50% 50%, #242424 0%, #000000 100%, #000000 100%);
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                animation: fadeIn 1.3s ease-in-out;
                .image-wrapper {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 3;
                    transition: all ease-in-out .5s;
                    top: 0;
                    will-change: height, top;
                    @include breakpoint(lg) {
                        height: 60%;
                    }
                    &.active-butterfly {
                        height: 200%;
                    }
                    &.active-vertical-strip, &.active-neck {
                        height: 200%;
                        top: 50%;
                    }
                    &.active-proof-label {
                        height: 200%;
                        top: -50%;
                    }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        transition: all ease-in-out .5s;
                    }

                    .Necks {
                        z-index: 2;
                    }

                    .VerticalStrips {
                        z-index: 1;
                    }
                }
            }

            .bottom {
                height: 40.5%;
                width: 100%;
                position: relative;
                display: flex;
                flex-flow: column;
                justify-content: space-around;
                border-top: 1.5px solid white;
                z-index: 13;
                animation: fadeIn 1.3s ease-in-out;
                @keyframes fadeIn {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }   
                }
                .white-blur {
                    width: 100%;
                    height: 138px;
                    position: absolute;
                    z-index: 0;
                    background: white;
                    top: 0;
                    left: 0;
                    filter: blur(186px);
                }
                .background {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    background: linear-gradient(180deg, #353535 0%, #242424 180.04%);
                    top: 0;
                    left: 0;
                    z-index: 1;
                }
                .btns-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                    width: 1225px;
                    max-width: 95%;
                    margin: 0 auto;
                    @include breakpoint(lg) {
                        flex-wrap: wrap;
                        gap: 30px;
                    }
                    @include breakpoint(sm) {
                        gap: 20px;
                    }
                    .btn {
                        background: transparent;
                        border: 1.5px solid #FFFFFF;
                        color: white;
                        @include breakpoint(lg) {
                            width: 45%;
                            padding: 8px 10px;
                        }
                        @include breakpoint(sm) {
                            width: calc(50% - 10px)
                        }
                        &.active {
                            background: white;
                            color: black;
                        }
                        &:hover {
                            background: white;
                            color: black;
                        }
                    }
                }
                .butterflies-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    position: relative;
                    z-index: 2;
                    width: 1370px;
                    max-width: 95%;
                    margin: 0 auto;
                    @include breakpoint(lg) {
                        overflow: scroll;
                    }
                    // display: grid;  
					// grid-template-columns: repeat(10, 1fr);
                    // grid-template-rows: auto;
                    // text-align: center;
                    // align-items: center;
                    // justify-items: center;
                    // justify-content: center;
                    // gap: 20px;

                    .butterfly {
                        cursor: pointer;
                        width: 100%;
                        text-align: center;
                        img {
                            width: 69px;
                            height: 40px;
                            object-fit: contain;
                            transform: scale(1);
                            transition: all .3s ease-in-out;
                        }
                        h6 { 
                            margin-top: 5px;
                            font-size: 16px
                        }
                        &:hover {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }

        #submit {
            #butterfly-8 {
                position: absolute;
                left: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterflyShort 3s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    animation: trailingButterflyShortMobile 3s ease-in-out;
                    animation-fill-mode: forwards;
                }
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
            #butterfly-9 {
                position: absolute;
                left: 150px;
                bottom: 10px;
                z-index: 9;
                animation: trailingButterflyRightShort 2.5s ease-in-out;
                animation-fill-mode: forwards;
                @include breakpoint(lg) {
                    animation: trailingButterflyRightShortMobile 2.5s ease-in-out;
                    animation-fill-mode: forwards;
                }
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }
            #butterfly-10 {
                position: absolute;
                right: 150px;
                bottom: 10px;
                z-index: 15;
                animation: submitRight 3.3s ease-in-out;
                animation-fill-mode: forwards;
                display: none;
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
                @keyframes submitRight {
                    0% {
                      transform: translateY(-18vh) translateX(-5vw) scale(1) rotate(-25deg) rotateY(-50deg);
                    }
                    // 10% {
                    //   transform: translateY(-16vh) translateX(-6.5vw) scale(1) rotate(-25deg) rotateY(50deg);
                    // }
                    20% {
                      transform: translateY(-14vh) translateX(-5vw) scale(1.1) rotate(10deg) rotateY(-100deg);
                    }
                    30% {
                      transform: translateY(-22vh) translateX(-7vw) scale(1.1) rotate(20deg) rotateY(50deg);
                    }
                    // 40% {
                    //   transform: translateY(-30vh) translateX(-5vw) scale(1) rotate(15deg) rotateY(-70deg);
                    // }
                    50% {
                      transform: translateY(-28vh) translateX(-3vw) scale(1) rotate(20deg) rotateY(40deg);
                    }
                    60% {
                      transform: translateY(-26vh) translateX(-2vw) scale(1.2) rotate(25deg) rotateY(105deg);
                    }
                    // 70% {
                    //   transform: translateY(-33vh) translateX(-1vw) scale(1.2) rotate(20deg) rotateY(-55deg);
                    // }
                    80% {
                      transform: translateY(-35vh) translateX(0vw) scale(1) rotate(45deg) rotateY(-40deg);
                    }
                    // 90% {
                    //   transform: translateY(-38vh) translateX(2vw) scale(1) rotate(40deg) rotateY(40deg);
                    // }
                    100% {
                      transform: translateY(-36vh) translateX(4vw) scale(1.25) rotate(55deg) rotateY(-30deg);
                    }
                }                  
            }
            #agree-rules {
                color: red;
                font-size: 12px;
                margin-bottom: 0;
                &.hide {
                    display: none;
                }
            }
            .submit-wrapper, .submitted-wrapper {
                display: flex;
                flex-flow: column;
                width: 670px;
                max-width: 95%;
                text-align: left;
                align-items: center;
                gap: 30px;
                animation: fadeIn 1s ease-in-out;
                max-height: 200vh;
                opacity: 1;
                transition: opacity .3s ease-in-out;
                h1 {
                    text-align: center;
                }
                a {
                    text-align: center;
                }
                .checkbox-wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    input {
                        margin-right: 10px;
                    }
                    label {
                        font-size: 16px;
                        font-family: $futura;
                        color: white;
                    }
                }
                &.hide {
                    opacity: 0;
                    max-height: 0;
                    overflow: hidden;
                    pointer-events: none;
                    max-width: 0;
                    * {
                        pointer-events: none;
                    }
                }
            }
            #loading {
                &.hide {
                    opacity: 0;
                    max-height: 0;
                    overflow: hidden;
                    pointer-events: none;
                    max-width: 0;
                    * {
                        pointer-events: none;
                    }
                } 
            }
            .submitted-wrapper {
                text-align: center;
            }
        }

        #login, #logged-in {
            height: 100vh;
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            background: linear-gradient(0deg, #000000 0%, rgba(44, 44, 44, 1) 149.15%, rgba(44, 44, 44, 1) 149.15%);
            h1 {
                margin-bottom: 30px;
            }
            .btn {
                margin: auto;
                padding: 8px 30px;
            }
            .data-wrapper {
                height: 60vh;
                overflow: scroll;
                width: 70vw;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: center;
                padding: 30px 0;
                h3 {
                    width: 100%;
                }
                .data-item {
                    border: 1px solid #ccc;
                    padding: 20px;
                    width: 100%;
                    max-width: 600px;
                }
                  
                  .color-choice, .form-submission, .file-url {
                    margin-top: 20px;
                  }
                  
                  .color-choice {
                    padding: 20px;
                  }
                  
                  .form-submission {
                    padding: 20px;
                  }
                  
                  .file-url {
                    border-top: 1px solid #ccc;
                    margin-top: 40px;
                    padding-top: 20px;
                  }
                  
                  h2 {
                    font-size: 1.2rem;
                    margin-bottom: 10px;
                  }
                  
                  strong {
                    font-weight: bold;
                  }
            }
        }
        #portal-closed {
            .page {
                gap: 15px;
            }
            #mystery-bottle {
                z-index: 5;
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
                left: 0;
                top: 0;
            }
            .logo {
                position: absolute !important;
            }
            #contact {
                width: 100% !important;
                .row, p {
                    width: 480px;
                    max-width: 100%;
                    margin: auto;
                }
                h3 {
                    font-size: 1.5rem;
                }
            }
            #butterfly-7 {
                z-index: 999999;
            }
            .form-wrapper {
                z-index: 999999;
            }
            .hide {
                display: none;
            }
            #text-wrapper {
                width: 600px;
                position: absolute;
                top: 50%;
                left: 75%;
                transform: translate(-50%, -50%);
                z-index: 50;
                display: flex;
                flex-flow: column;
                gap: 15px;
                @include breakpoint(lg) {
                    width: 100%;
                    left: 50%;
                    top: 150px;
                    padding: 30px;
                }
                p {
                    h4 {
                        display: inline-block;
                        font-size: 1.5rem;
                        @include breakpoint(md) {
                            font-size: 1.2rem;
                        }
                    }
                }
                h2 {
                    font-size: 2.2rem;
                }
            }
            .submit-text {
                display: flex;
                flex-flow: column;
                gap: 15px;
                .small {
                    font-size: 15px;
                    margin-top: 20px;
                }
                .social-icons {
                    display: flex;
                    gap: 20px;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 30px;
                        height: 30px;
                        filter: invert(1);
                    }
                }
            }
        }
    }
}



#loading {
  --bg-image: var(--body-image);
  --animation-time: 3s;
  --dot-size: 20px;
  --dots-length: calc(7 * var(--dot-size));
  --loading-width: 100vw;
  width: var(--loading-width);
  height: var(--dot-size);
  
  display: flex;
  position: relative;
  
  overflow: hidden;
  
/*   background: rgba(0,0,0,0.2); */
  
}
#loading::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  
  background: linear-gradient(to right, var(--bg-image), transparent 10% 90%, var(--bg-image));
}
#loading > span {
  display: inline-block;
  position: absolute;
  
  background: $blue;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 50%;
  
  --initial-left: 0px;
}
#loading > span:nth-child(1) {
  --initial-left: 0px;
  --animation-delay: calc(4/10 * var(--animation-time));
}
#loading > span:nth-child(2) {
  --initial-left: calc(1.5 * var(--dot-size));
  --animation-delay: calc(3/10 * var(--animation-time));
}
#loading > span:nth-child(3) {
  --initial-left: calc(3 * var(--dot-size));
  --animation-delay: calc(2/10 * var(--animation-time));
}
#loading > span:nth-child(4) {
  --initial-left: calc(4.5 * var(--dot-size));
  --animation-delay: calc(1/10 * var(--animation-time));
}
#loading > span:nth-child(5) {
  --initial-left: calc(6 * var(--dot-size));
  --animation-delay: calc(0/10 * var(--animation-time));
}
#loading > span {
  animation: move var(--animation-time) cubic-bezier(0.3,1,0.5,0) infinite;
  animation-delay: var(--animation-delay);
  
  left: calc(var(--initial-left) - var(--dots-length));
}

@keyframes move {
  50%, 100% {
    left: calc(100% + var(--initial-left));
  }
}